import _ from 'lodash'
window._ = _

import Moment from 'moment'
import { extendMoment } from 'moment-range'
extendMoment(Moment)
window.moment = Moment

import Cropper from 'cropperjs'
Object.assign(window, { Cropper })

import * as Bootstrap from 'bootstrap'
window.Bootstrap = Bootstrap

import * as Popper from 'popper.js'
window.Popper = Popper.defaults

import 'laravel-datatables-vite-bs4'
import '~vendor/yajra/laravel-datatables-buttons/src/resources/assets/buttons.server-side.js'

import '~adminlte/dist/js/adminlte'
import Swal from 'sweetalert2'
import 'bootstrap-datepicker'
import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS
window.Swal = Swal
