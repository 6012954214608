import { createApp, h } from 'vue'
import { createStore } from 'vuex'
import { createInertiaApp } from '@inertiajs/vue3'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import modules from './store/modules'
import Toasted from '@hoppscotch/vue-toasted'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import Popper from 'vue3-popper'
import translation from './translation'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import { i18nVue } from 'laravel-vue-i18n'
import LaravelPermissionsToVue from './plugins/LaravelPermissionsToVue'

let store = new createStore({
  modules
})

const lang = document.querySelector('html').getAttribute('lang')

createInertiaApp({
  resolve: name =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),

  setup({ el, App, props }) {
    createApp({
      render: () => h(App, props)
    })
      .component('Popper', Popper)
      .use(LaravelPermissionsToVue)
      .use(Toasted, {
        iconPack: 'fontawesome',
        position: 'top-center',
        theme: 'bubble'
      })
      .use(i18nVue, {
        resolve: async lang => {
          const langs = import.meta.glob('../../lang/*.json')
          return await langs[`../../lang/${lang}.json`]()
        }
      })
      .use(store)
      .use(VueToast)
      .use(VueSweetalert2)
      .mixin(translation) //legacy lang
      .mount(el)
  }
})
